.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

code {
  color: blueviolet;
}

.Main {
  background-image: url('sibg.png');
  background-size: 100% 100%;
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Who {
  font-size: large;
}

.Desc {
  font-size: large;
}